import { useLocation, useNavigate, Link } from "react-router-dom";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Button, AppBar, Toolbar, Container } from "@mui/material";
// hooks
import useOffSetTop from "../../hooks/useOffSetTop";
import useResponsive from "../../hooks/useResponsive";
// utils
import cssStyles from "../../utils/cssStyles";
// config
import { HEADER } from "../../config";
// components
// import { Logo } from "../../assets/";
import Logo from "../../components/Logo";
//
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import navConfig from "./MenuConfig";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { logOut } from "../../redux/redux-slices/UserSlice";
import axios from "axios";

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: "`calc(100% - 48px)`",
}));

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "md");
  const isAuthenticated = useAppSelector((state) => state.user.authenticated);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/logout`);
      localStorage.removeItem("access_token");
      dispatch(logOut());
      navigate("/login");
    } catch (err) {}
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }, []);

  return (
    <AppBar
      sx={{
        boxShadow: 0,
        background:
          "linear-gradient(90deg, rgba(255,169,249,1) 0%, rgba(255,249,121,1) 100%, rgba(255,247,173,1) 100%)",
        transition: theme.transitions.create(["background-color", "height"], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.shorter,
        }),
        ...(isOffset && {
          ...cssStyles(theme).bgBlur(),
          // Slightly transparent background when scrolled
          backgroundColor: "rgba(255,169,249,0.8)", 
          height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
        }),
      }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "fixed",
              left: { xs: 20, md: 80 },
            }}
          >
            <Link to='/' style={{ textDecoration: "none" }}>
              <Logo />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop ? (
            <MenuDesktop isOffset={isOffset} isHome={pathname === "/"} navConfig={navConfig} />
          ) : (
            <MenuMobile isOffset={isOffset} isHome={pathname === "/"} navConfig={navConfig} />
          )}

          {isAuthenticated ? (
            <Button variant='text' sx={{ color: "grey.700" }} onClick={handleLogOut}>
              LOG OUT
            </Button>
          ) : (
            <Link to='/login' style={{ textDecoration: "none", color: "inherit" }}>
              <Button variant='text' sx={{ color: "grey.700" }}>
                LOG IN
              </Button>
            </Link>
          )}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
