import React, { useState, useRef } from 'react';
import { Icon } from "@iconify/react";
import { Button, Paper, Stack, Typography, IconButton, Snackbar, Container } from "@mui/material";
import SectionContainer from "layouts/main/SectionContainer";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { secondaryButtonStyles } from "utils/cssStyles";
import axios from "axios";
import Notification from "components/Notification";

export default function CodeVerification() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [code, setCode] = useState(["", "", "", ""]);
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
      const newCode = [...code];
      for (let i = index; i >= 0; i--) {
        if (newCode[i] !== '') {
          newCode[i] = '';
          setCode(newCode);
          inputRefs.current[i]?.focus();
          break;
        }
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 4);
    if (pastedData.length === 4 && /^\d{4}$/.test(pastedData)) {
      const newCode = pastedData.split('');
      setCode(newCode);
      newCode.forEach((_, index) => {
        if (inputRefs.current[index]) {
          inputRefs.current[index]!.value = newCode[index];
        }
      });
      inputRefs.current[3]?.focus();
    }
  };

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<{ code1: string; code2: string; code3: string; code4: string }>();

  const onSubmit: SubmitHandler<{
    code1: string;
    code2: string;
    code3: string;
    code4: string;
  }> = async () => {
    try {
      const verificationCode = code.join("");
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/verification/${verificationCode}`);
      setOpenSnackbar(true);
      setSnackbarMessage(true);
      setTimeout(() => {}, 10000);
      res.status === 200 && navigate("/create-password", {state: {id: res.data}, replace: true});
    } catch (error: any) {
      if(error?.response?.status === 404) {
        setMessage("Invalid token!");
      } else {
        setMessage("Network error!");
      }
      setOpen(true);
      setSnackbarMessage(false);
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  const action = (
    <>
      <Button color='secondary' size='small' onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
        <Icon icon='iconoir:cancel' />
      </IconButton>
    </>
  );

  return (
    <SectionContainer>
      <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Stack spacing={4} alignItems="center">
          <Typography variant='h2' sx={{ color: "common.black", textAlign: 'center' }}>
            OTP Verification
          </Typography>

          <Typography
            variant='subtitle1'
            sx={{ color: "#96989D", textAlign: "center" }}
          >
            Enter the verification code we just sent on your email address.
          </Typography>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
            action={action}
            message={snackbarMessage ? "Code verified" : "Error verifying code"}
          />

          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Stack spacing={2} alignItems="center">
              <Paper
                elevation={0}
                component='div'
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  gap: 2,
                }}
              >
                {[0, 1, 2, 3].map((index) => (
                  <input
                    key={index}
                    type='text'
                    maxLength={1}
                    name={`code${index + 1}`}
                    value={code[index]}
                    onChange={handleChange(index)}
                    onKeyDown={handleKeyDown(index)}
                    onPaste={index === 0 ? handlePaste : undefined}
                    ref={(el) => inputRefs.current[index] = el}
                    style={{
                      width: "60px",
                      fontStyle: "bold",
                      fontSize: "24px",
                      height: "60px",
                      border: "1px solid #E8ECF4",
                      borderRadius: "8px",
                      textAlign: "center",
                      background: code[index] ? "#FFFFFF" : "#E8ECF4",
                    }}
                    required
                    onFocus={(e) => (e.target as HTMLInputElement).style.background = "#FFFFFF"}
                    onBlur={(e) => (e.target as HTMLInputElement).style.background = (e.target as HTMLInputElement).value ? "#FFFFFF" : "#E8ECF4"}
                  />
                ))}
              </Paper>
              {errors.code1 && (
                <Typography variant="caption" color="error">
                  Code is required
                </Typography>
              )}

              <Button 
                variant='contained' 
                size='large' 
                sx={{ 
                  ...secondaryButtonStyles, 
                  width: '100%', 
                  maxWidth: '300px' 
                }} 
                type='submit'
              >
                Verify
              </Button>
            </Stack>
          </form>
          <Typography variant='subtitle1' sx={{ color: "#96989D", textAlign: "center" }}>
            Please enter the 4-digit code sent to your email.
          </Typography>
        </Stack>
      </Container>
      <Notification 
        message={message}
        show={open}
        setShow={setOpen}
      />
    </SectionContainer>
  );
}
