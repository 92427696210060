import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import Navbar from "./Navbar";
import Artistinfo from "./Sidebar";
import TabBar from "./TabBar";
import PostsAndComments from "./PostsAndComments";
import { ArtsitBackgroundImage } from "assets";
import { ArtistProps } from "@types";

interface BackgroundImageProps {
  backgroundUrl: string;
}

const BackgroundImageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'backgroundUrl'
})<BackgroundImageProps>(({ theme, backgroundUrl }) => ({
  backgroundImage: `url(${backgroundUrl})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: "208px",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    height: "108px",
  },
}));

export default function CommunityFanPage() {
  const { username } = useParams();
  const [artist, setArtist] = useState<ArtistProps>();
  const [activeTab, setActiveTab] = useState("Community");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const getUserDetails = async() => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/artist/${username}`);
        setArtist(res.data);
      } catch(err) {
        console.log(err);
      }
    }
    getUserDetails();
  }, [username]);

  const renderContent = () => {
    if (isMobile) {
      if (activeTab === "Artistinfo") {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Artistinfo 
                artistName={artist?.username || ""} 
                memberCount={artist?.fans || 0}
                artistImage={artist?.avatarImg}
              />
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid container>
          <Grid item xs={12}>
            <PostsAndComments artistId={artist?._id} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <PostsAndComments artistId={artist?._id} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Artistinfo 
            artistName={artist?.username || ""} 
            memberCount={artist?.fans || 0}
            artistImage={artist?.avatarImg}
            artistId={artist?._id}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <main>
        <BackgroundImageContainer 
          backgroundUrl={artist?.bannerImg || ArtsitBackgroundImage}
        />
        <TabBar 
          activeTab={activeTab}
          onTabChange={setActiveTab}
          showArtistinfoTab={isMobile}
        />
        <Box sx={{ width: "100%", padding: 0, margin: 0 }}>
          {renderContent()}
        </Box>
      </main>
    </Box>
  );
}