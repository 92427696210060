import React, { useState } from "react";
import { Box, Stack, Typography, IconButton, Paper, Button, Menu, MenuItem, TextField } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VoteButtons from "./VoteButtons";
import CommentForm from "./CommentForm";
import { CommentType } from "@types";
// import TimeAgo from "./TimeAgo";

interface CommentProps extends CommentType {
  onReply: (parentId: string, text: string, username: string) => void;
  onLoadMore: (parentId: string) => void;
  onEdit: (commentId: string, newText: string) => void;
  onDelete: (commentId: string) => void;
  depth: number;
  isReply?: boolean;
  // timestamp: string | number | Date;
}

const Comment: React.FC<CommentProps> = ({
  _id,
  username,
  text,
  count,
  userVote,
  replies,
  onReply,
  onLoadMore,
  onEdit,
  onDelete,
  depth,
  isReply = false,
  // timestamp, // Destructure timestamp prop
}) => {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [displayedReplies, setDisplayedReplies] = useState(1);
  const [showReplies, setShowReplies] = useState(false);

  const handleReply = (replyText: string) => {
    onReply(_id, replyText, username);
    setShowReplyForm(false);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    handleMenuClose();
  };

  const handleDeleteClick = () => {
    onDelete(_id);
    handleMenuClose();
  };

  const handleEditSubmit = () => {
    if (editedText.trim() !== "") {
      onEdit(_id, editedText);
      setIsEditing(false);
    }
  };

  const handleLoadMore = (event: React.MouseEvent) => {
    event.stopPropagation();
    onLoadMore(_id);
    setDisplayedReplies(replies.length);
  };

  const toggleReplies = (event: React.MouseEvent) => {
    if (replies.length > 0) {
      setShowReplies(!showReplies);
    }
  };

  const handleReplyButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowReplyForm(!showReplyForm);
  };

  const renderReplies = () => {
    if (replies.length === 0) return null;

    return (
      <Box
        sx={{
          pl: 2,
          mt: 1,
          ml: 1,
          borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
          position: "relative",
          display: showReplies ? "block" : "none",
        }}
      >
        {replies.slice(0, displayedReplies).map((reply) => (
          <Comment
            key={reply._id}
            {...reply}
            onReply={onReply}
            onLoadMore={onLoadMore}
            onEdit={onEdit}
            onDelete={onDelete}
            depth={depth + 1}
            isReply={true}
            // timestamp={reply.createdAt}
          />
        ))}
        
        {showReplies && displayedReplies < replies.length && (
          <Button 
            onClick={handleLoadMore} 
            size="small" 
            sx={{ 
              mt: 0.5,
              color: "text.secondary",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)"
              }
            }}
          >
            Load more replies ({replies.length - displayedReplies})
          </Button>
        )}
      </Box>
    );
  };

  // Ensure timestamp is a valid date object before passing to TimeAgo
  // const parsedTimestamp = new Date(timestamp);
  // if (isNaN(parsedTimestamp.getTime())) {
  //   console.error('Invalid timestamp:', timestamp);
  // }

  return (
    <Paper
      elevation={0}
      sx={{
        bgcolor: "background.default",
        mb: 1,
        position: "relative",
        cursor: replies.length > 0 ? "pointer" : "default",
        "&:hover": replies.length > 0 ? {
          bgcolor: "rgba(0, 0, 0, 0.02)"
        } : {},
        "&::before": isReply
          ? {
              content: '""',
              position: "absolute",
              left: "-19px",
              top: "27px",
              width: "5px",
              height: "5px",
              borderRadius: "50%",
              backgroundColor: "rgba(0, 0, 0, 0.22)",
              zIndex: 1,
            }
          : {},
      }}
      onClick={toggleReplies}
    >
      <Stack direction="row" alignItems="start" spacing={1}>
        <Box>
          {/* <VoteButtons
            initialCount={count}
            initialUserVote={userVote}
            onVote={(newVote) => onVote(_id, newVote)}
          /> */}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2" sx={{ fontWeight: "bold", color: "#FD934C"}}>
              {username}
            </Typography>
            {/* <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.5)" }}>
              <TimeAgo timestamp={parsedTimestamp} />
            </Typography> */}
            {replies.length > 0 && (
              <Typography variant="caption" color="text.secondary">
                ({replies.length} {replies.length === 1 ? 'reply' : 'replies'})
              </Typography>
            )}
            {replies.length > 0 && (
              <IconButton 
                size="small" 
                onClick={(e) => {
                  e.stopPropagation();
                  setShowReplies(!showReplies);
                }}
              >
                {showReplies ? (
                  <KeyboardArrowUpIcon fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </IconButton>
            )}
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
            {isEditing ? (
              <Box sx={{ width: "100%", pr: 2 }} onClick={(e) => e.stopPropagation()}>
                <TextField
                  fullWidth
                  multiline
                  value={editedText}
                  onChange={(e) => setEditedText(e.target.value)}
                  size="small"
                  sx={{ mb: 1 }}
                />
                <Stack direction="row" spacing={1}>
                  <Button size="small" variant="contained" onClick={handleEditSubmit}>
                    Save
                  </Button>
                  <Button size="small" onClick={() => setIsEditing(false)}>
                    Cancel
                  </Button>
                </Stack>
              </Box>
            ) : (
              <>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  {text}
                </Typography>
                <Box sx={{ flexShrink: 0 }}>
                  <Stack direction="row" spacing={0.5}>
                    {depth < 3 && (
                      <IconButton size="small" onClick={handleReplyButtonClick}>
                        <ReplyIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton size="small" onClick={handleMenuClick}>
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </Box>
              </>
            )}
          </Box>
          {showReplyForm && (
            <Box sx={{ mt: 1 }} onClick={(e) => e.stopPropagation()}>
              <CommentForm onSubmit={handleReply} placeholder="Write a reply..." />
            </Box>
          )}
        </Box>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <MenuItem onClick={handleEditClick}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>

      {depth < 3 && renderReplies()}
    </Paper>
  );
};

export default Comment;
