import React, { useState } from "react";
import { Box, Stack, Typography, IconButton, TextField, Button } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { PostType } from "@types";
import VoteButtons from "./VoteButtons";
import CommentForm from "./CommentForm";
import Image from "components/Image";
// import TimeAgo from "./TimeAgo";

interface PostProps {
  post: PostType;
  onVote: (delta: number) => void;
  onAddComment: (text: string) => void;
  onEditPost: (updatedDesc: string) => void;
  onDeletePost: () => void;
  toggleCommentsVisibility: (postId: string) => void;
  visibleComments: Record<string, boolean>;
  getCommentCount: (postId: string) => number;
  showCommentForm: boolean;
  onToggleCommentForm: () => void;
  isEditing: boolean;
}

const Post: React.FC<PostProps> = ({
  post,
  onVote,
  onAddComment,
  onEditPost,
  onDeletePost,
  toggleCommentsVisibility,
  visibleComments,
  getCommentCount,
  showCommentForm,
  onToggleCommentForm,
  isEditing,
}) => {
  const [editMode, setEditMode] = useState(isEditing);
  const [updatedDesc, setUpdatedDesc] = useState(post.desc);

  const styles = {
    modBadge: {
      backgroundColor: "#FD934C",
      color: "#fff",
      padding: "2px 4px",
      borderRadius: "2px",
      width: "32px",
      fontSize: "10px",
      textAlign: "center",
    },
    postTitle: {
      width: "100%",
      fontSize: { xs: "16px", sm: "18px", md: "24px" },
      fontWeight: "normal",
    },
    username: {
      color: "#FD934C",
      fontWeight: "bold",
    },
    iconButton: {
      color: "rgba(0, 0, 0, 0.38)",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      borderRadius: "50%",
      width: 24,
      height: 24,
      mt: 1,
      "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
    },
    postCard: {
      borderRadius: "8px",
      boxShadow: 3,
      padding: 2,
      backgroundColor: "#fff",
      marginBottom: 3,
      display: "flex",
      flexDirection: "column",
    },
  };

  const handleEditToggle = () => setEditMode((prev) => !prev);

  const handleEditSubmit = () => {
    onEditPost(updatedDesc);
    setEditMode(false);
  };

  const renderMedia = () => {
    if (!post.media) return null;

    switch (post.mediaType) {
      case "image":
      case "gif":
        return (
          <Box sx={{ width: "100%", paddingTop: "62.7%", position: "relative" }}>
            <Image
              src={post.media || undefined}
              alt={post.mediaType === "gif" ? "GIF" : "Post"}
              loading='lazy'
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </Box>
        );
      case "video":
        return (
          <video
            src={post.media || undefined}
            controls
            style={{ width: "100%", maxHeight: "400px", objectFit: "contain" }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={styles.postCard}>
      <Stack direction='row' spacing={2} alignItems='flex-start'>
        <VoteButtons initialCount={post.count} initialUserVote={post.userVote} onVote={onVote} />
        <Stack spacing={1} alignItems='flex-start' width='100%'>
          {editMode ? (
            <Stack spacing={1}>
              <TextField
                value={updatedDesc}
                onChange={(e) => setUpdatedDesc(e.target.value)}
                fullWidth
                multiline
                variant='outlined'
                size='small'
                sx={{
                  mt: 1,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "black",
                    },
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                }}
              />
              <Button
                variant='contained'
                onClick={handleEditSubmit}
                sx={{
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "#333",
                  },
                }}
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Typography variant='h6' sx={styles.postTitle}>
              {post.desc}
            </Typography>
          )}
          {post.media && (
            <Box
              sx={{
                width: "100%",
                maxWidth: { xs: "100%", sm: "370px" },
                mt: 2,
                overflow: "hidden",
              }}
            >
              {renderMedia()}
              <Typography
                variant='body2'
                sx={{ color: "rgba(0, 0, 0, 0.5)", fontSize: { xs: "12px", sm: "14px" } }}
              >
                Posted by <span style={styles.username}>{post.username}</span>
              </Typography>
            </Box>
          )}
          <Stack
            direction='row'
            spacing={1}
            mt={2}
            alignItems='center'
            justifyContent='space-between'
            sx={{ width: "100%" }}
          >
            {/* Left side - Post icons */}
            <Stack direction='row' spacing={1}>
              <IconButton
                size='small'
                aria-label='Comment on Post'
                onClick={onToggleCommentForm}
                sx={styles.iconButton}
              >
                <CommentIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <IconButton
                size='small'
                aria-label='Edit Post'
                onClick={handleEditToggle}
                sx={styles.iconButton}
              >
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              <IconButton
                size='small'
                aria-label='Delete Post'
                onClick={onDeletePost}
                sx={styles.iconButton}
              >
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>
              {/* <IconButton size='small' aria-label='Share Post' sx={styles.iconButton}>
                <ShareIcon sx={{ fontSize: "16px" }} />
              </IconButton> */}
            </Stack>

            {/* Right side - Time */}
            <Button
              size='small'
              onClick={() => toggleCommentsVisibility(post._id)}
              sx={{ textTransform: "none", color: "black" }}
            >
              {visibleComments[post._id]} Comments ({getCommentCount(post._id)})
            </Button>

            {/* <Typography variant='body2' sx={{ color: "rgba(0, 0, 0, 0.5)" }}>
              <TimeAgo timestamp={post.createdAt} />
            </Typography> */}
          </Stack>
        </Stack>
      </Stack>
      {showCommentForm && (
        <Box sx={{ width: "100%", mt: 2 }}>
          <CommentForm onSubmit={onAddComment} />
        </Box>
      )}
    </Box>
  );
};

export default Post;
