import React, { useState, useEffect, SetStateAction } from "react";
import { Snackbar, IconButton, useTheme, Typography, LinearProgress, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface NotificationProps {
  message: string;
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
}

const Notification = ({ message, show, setShow }: NotificationProps) => {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const [open, setOpen] = useState(false);

  useEffect(() => {
    show && setOpen(show);
  }, [show]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={show}
      autoHideDuration={5000}
      onClose={handleClose}
      message={
        <Stack direction='column' spacing={1} sx={{ width: "100%" }}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Typography variant='body1' sx={{ fontWeight: "bold", color: "white" }}>
              {message}
            </Typography>
          </Stack>
          <LinearProgress sx={{ width: "100%" }} />
        </Stack>
      }
      action={
        <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
          <CloseIcon fontSize='small' />
        </IconButton>
      }
      sx={{
        width: "80%",
        padding: theme.spacing(1),
        margin: theme.spacing(0.25, 0),
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.up("md")]: {
          minWidth: 240,
        },
      }}
    />
  );
};

export default Notification;
