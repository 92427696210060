// @mui
import { styled } from "@mui/material/styles";
import { Box, Container, Typography, Stack } from "@mui/material";
// components
import Image from "../../components/Image";
import { MotionInView, varFade } from "../../components/animate";
import { LikeIceSpice } from "assets";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 0),
  backgroundColor: "rgba(248, 248, 248, 1)",
  position: "relative",
  marginTop: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Questions() {
  return (
    <Container sx={{ position: "relative" }}>
      <ContentStyle>
        <Typography variant='h2' sx={{ color: "common.black" }}>
          Got Questions?
        </Typography>
        <Stack spacing={2} direction={{ xs: "column", sm: "row" }} sx={{ mt: 2 }}>
          <Stack spacing={2}>
            <Stack spacing={2} sx={{ borderRadius: 2, bgcolor: "common.white", padding: 6 }}>
              <Typography variant='h5' sx={{ color: "common.black" }}>
                What fandom can I join ?
              </Typography>
              <Typography variant='subtitle2' sx={{ color: "common.black" }}>
                Every artist has a fandom on SOUNDCTRL. You can just search for your favorite artist
                and join their fandom. If your favorite artist is not on here yet, don't worry we
                add new artists every day 🙃
              </Typography>
            </Stack>
            <Stack spacing={2} sx={{ borderRadius: 2, bgcolor: "common.white", padding: 6 }}>
              <Typography variant='h5' sx={{ color: "common.black" }}>
                What happens after I join a fandom?
              </Typography>
              <Typography variant='subtitle2' sx={{ color: "common.black" }}>
                The magic begins. Once in the official fandom, you can "book time" with the artist
                you subscribe to. This is a private video 1on1 call with the artist.
              </Typography>
              {/* <Typography variant='subtitle2' sx={{ color: "common.black" }}>
                You will also unlock SuperChat. A chat room where you will see exclusive content,
                live streams, conversation, memes, listening parties, and much more from your
                favorite artist.
              </Typography> */}
              <Typography variant='subtitle2' sx={{ color: "common.black" }}>
                Connect your Spotify to earn points for the music you stream. Just like airline
                miles, you can cash your points in for merch, tickets, and experiences. A chat room
                where you will see exclusive content, live streams, conversations, memes, listening
                parties, and much more from your favorite artist.
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              sx={{ bgcolor: "rgba(191, 241, 49, 1)", position: "relative", borderRadius: 2 }}
            >
              <Stack spacing={2} sx={{ mt: 5, padding: 3 }}>
                <Typography variant='h5' sx={{ color: "common.black" }}>
                  Are SOUNDCTRL Fandom Official?
                </Typography>
                <Typography variant='subtitle2' sx={{ color: "common.black", width: "38ch" }}>
                  Yes! Our mission is to give all super fans access and community to their favorite
                  artist. Each verified fandom is ran by the artists or the artist team. Community
                  led fandoms are run by dedicated super fans from the community until the artist
                  claims the fandom. 🫶
                </Typography>
              </Stack>
              <Image
                src={LikeIceSpice}
                alt='banner image'
                sx={{
                  position: "relative",
                  top: 0,
                  height: 400,
                  width: 400,
                  display: { xs: "none", sm: "block" },
                }}
              />
            </Stack>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
              <Stack
                spacing={2}
                sx={{ borderRadius: 2, bgcolor: "rgba(253, 147, 76, 1)", padding: 6 }}
              >
                <Typography variant='h5' sx={{ color: "common.black" }}>
                  Can I get kicked out of a fandom?
                </Typography>
                <Typography variant='subtitle2' sx={{ color: "common.black" }}>
                  We love the passion of our users! Ultimately the artist and their team decide the
                  rules of each fandom
                </Typography>
              </Stack>
              <Stack spacing={2} sx={{ borderRadius: 2, bgcolor: "common.white", padding: 6 }}>
                <Typography variant='h5' sx={{ color: "common.black" }}>
                  What are Fan Experience Points?
                </Typography>
                <Typography variant='subtitle2' sx={{ color: "common.black" }}>
                  On SoundCTRL you are rewarded for being a superfan, the more support your favorite
                  on and off this platform the more points you will receive. Points can be redeemed
                  for experiences, merch, tickets, and surprises.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </ContentStyle>
    </Container>
  );
}
