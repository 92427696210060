import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, Skeleton } from "@mui/material";
import { UserProps } from "@types";
import Image from "components/Image";
import { styled } from "@mui/material/styles";

const ImageWrapper = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  "& img": {
    transition: "transform 0.3s ease-in-out",
  },
  "&:hover img": {
    transform: "scale(1.1)",
  },
}));

const CardWrapper = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  overflow: "hidden",
  backgroundColor: theme.palette.common.white,
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[4],
  },
}));

export default function ArtistCommunityCard({ username, name, avatarImg }: UserProps) {
  const [loading, setLoading] = useState(true);

  // Simulate loading state (replaced with actual data fetching logic)
  useEffect(() => {
    setTimeout(() => {
      // Mock loading complete after 2 seconds
      setLoading(false); 
    }, 2000);
  }, []);

  return (
    <CardWrapper>
      <ImageWrapper>
        {loading ? (
          <Skeleton variant="rectangular" width="350px" height="250px" />
        ) : (
          <Image
            src={avatarImg}
            alt={`${name}'s profile`}
            sx={{
              height: 250,
              width: 350,
              objectFit: "cover",
              display: "block",
            }}
          />
        )}
      </ImageWrapper>

      <Stack 
        spacing={0.5} 
        sx={{ 
          padding: 2,
          bgcolor: "background.paper",
        }}
      >
        {loading ? (
          <>
            <Skeleton width="80%" height={24} />
            <Skeleton width="60%" height={20} />
          </>
        ) : (
          <>
            <Typography
              variant="h4"
              sx={{
                color: "common.black",
                textTransform: "uppercase",
                fontWeight: "bold",
                fontFamily: "Dela Gothic One, regular",
                fontSize: "1.25rem",
                lineHeight: 1.2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {name}
            </Typography>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                color: "grey.600",
                textTransform: "lowercase",
                fontWeight: "medium",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              @{username?.toLowerCase()}
            </Typography>
          </>
        )}
      </Stack>
    </CardWrapper>
  );
}
