import React, { useState } from "react";
import { Stack, TextField, Button } from "@mui/material";

interface CommentFormProps {
  onSubmit: (text: string) => void;
  onCancel?: () => void;
  placeholder?: string;
  showCancelButton?: boolean;
}

const CommentForm: React.FC<CommentFormProps> = ({
  onSubmit,
  placeholder = "Write a comment...",
}) => {
  const [text, setText] = useState('');

  const handleSubmit = () => {
    if (text.trim()) {
      onSubmit(text);
      setText('');
    }
  };

  return (
    <Stack direction='row' spacing={1} alignItems='start' sx={{ mt: 1 }}>
      <TextField
        fullWidth
        variant='outlined'
        size='small'
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder={placeholder}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#222222",
              borderWidth: "1.2px !important",
            },
            "&:hover fieldset": {
              borderColor: "#222222",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#222222",
            },
          },
        }}
      />
      <Button
        variant='contained'
        size='small'
        onClick={handleSubmit}
        sx={{
          backgroundColor: "#000000",
          color: "#ffffff",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)", 
          "&:hover": {
            backgroundColor: "#333333",
          },
        }}
      >
        Submit
      </Button>
    </Stack>
  );
};

export default CommentForm;
