import { useLocation, Outlet } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import MainFooter from "./MainFooter";
import MainHeader from "./MainHeader";

export default function MainLayout() {
  const { pathname } = useLocation();

  // Hiding the MainHeader and MainFooter components based on the current pathname
  const hideHeaderAndFooter = pathname === "/community";

  return (
    <Stack sx={{ minHeight: 1 }}>
      {!hideHeaderAndFooter && <MainHeader />}  {/* Only show header if it's not the community page */}

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {!hideHeaderAndFooter && <MainFooter />}  {/* Only show footer if it's not the community page */}
    </Stack>
  );
}
