import { useState } from "react";
import { Box, Link, Stack, Typography, IconButton } from "@mui/material";
import { BookingProps } from "@types";
import { TicketOne } from "assets";
import Image from "components/Image";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function AcceptedTicketCard({ username, time, link }: BookingProps) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Box sx={{ bgcolor: "common.white", borderRadius: 2, mb: 2 }}>
      <Image src={TicketOne} alt='ticket image' />
      <Stack spacing={1} sx={{ padding: 2, bgcolor: "common.white", borderRadius: 5 }}>
        <Stack spacing={0}>
          <Typography
            variant='subtitle1'
            sx={{
              fontWeight: 700,
              fontSize: { xs: '1rem', sm: '1.125rem', md: '1.25rem' },
            }}
          >
            Meeting with @{username}
          </Typography>
          <Typography
            variant='subtitle2'
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' },
            }}
          >
            {time}
          </Typography>
        </Stack>

        <Stack>
          <Stack spacing={1} direction='row' alignItems='center'>
            <Typography
              variant='subtitle2'
              sx={{
                fontWeight: 700,
                fontSize: { xs: '0.875rem', sm: '1rem', md: '1.125rem' },
              }}
            >
              Meeting link
            </Typography>
            <CopyToClipboard text={link} onCopy={handleCopy}>
              <IconButton>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </CopyToClipboard>
            {copied && (
              <Typography
                variant='caption'
                sx={{
                  fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
                  color: "rgba(34, 34, 34, 1)",
                }}
              >
                Copied!
              </Typography>
            )}
          </Stack>
          <Link
            // size='small'
            sx={{
              color: "rgba(51, 153, 255, 1)",
              fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
              textTransform: "lowercase",
            }}
          >
            {link}
          </Link>
          <Link
            sx={{
              fontSize: { xs: '0.65rem', sm: '0.775rem', md: '.9rem' },
              color: "rgba(34, 34, 34, 1)",
            }}
          >
            A CONFIRMATION HAS BEEN SENT
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}
