import React, { useEffect, useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import PostCreationForm from "./components/PostCreationForm";
import Post from "./components/Post";
import CommentsSection from "./components/CommentsSection";
import { PostType, CommentType } from "@types";
import Notification from "components/Notification";
import CommentIcon from "@mui/icons-material/Comment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";

interface Props {
  artistId: string | undefined;
}

const PostsAndComments: React.FC<Props> = ({ artistId }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [posts, setPosts] = useState<PostType[]>([]);
  const [comments, setComments] = useState<Record<string, CommentType[]>>({});
  const [isPostBoxVisible, setIsPostBoxVisible] = useState(false);
  const [visibleComments, setVisibleComments] = useState<Record<string, boolean>>({});
  const [activeCommentForm, setActiveCommentForm] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({});
  const [editingPost, setEditingPost] = useState<string | null>(null);

  const handleCreatePost = async (
    content: string,
    media: string | null,
    mediaType: "image" | "video" | "gif" | null
  ) => {
    if (!artistId) return;

    const newPost = {
      count: 0,
      mediaType,
      desc: content,
      media,
      userVote: 0,
      artistId,
    };

    try {
      setIsLoading((prev) => ({ ...prev, createPost: true }));
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/posts/`, newPost);
      setPosts((prevPosts) => [res.data, ...prevPosts]);
      setComments((prevComments) => ({ ...prevComments, [res.data._id]: [] }));
      setVisibleComments((prev) => ({ ...prev, [res.data._id]: false }));
      setIsPostBoxVisible(false);
      handleNotification("Post created successfully");
    } catch (err) {
      handleNotification("Failed to create post", "error");
      console.error(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, createPost: false }));
    }
  };

  const handleNotification = (message: string, type: "success" | "error" = "success") => {
    setNotificationMessage(message);
    setShowNotification(true);
  };

  const handleVotePost = async (postId: string, delta: number) => {
    try {
      setIsLoading((prev) => ({ ...prev, [`vote_${postId}`]: true }));
      await axios.post(`${process.env.REACT_APP_BASE_URL}/posts/${postId}/vote`, { delta });
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId ? { ...post, count: Math.max(0, post.count + delta) } : post
        )
      );
    } catch (err) {
      handleNotification("Failed to update vote", "error");
      console.error(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, [`vote_${postId}`]: false }));
    }
  };

  const handleAddComment = async (postId: string, text: string) => {
    const newComment = {
      text,
      postId,
      count: 0,
      userVote: 0,
    };
    try {
      setIsLoading((prev) => ({ ...prev, [`comment_${postId}`]: true }));
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/comments/`, newComment);

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: [res.data, ...(prevComments[postId] || [])],
      }));

      setVisibleComments((prev) => ({ ...prev, [postId]: true }));
      setActiveCommentForm(null);
      handleNotification("Comment added successfully");
    } catch (err) {
      handleNotification("Failed to add comment", "error");
      console.error(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, [`comment_${postId}`]: false }));
    }
  };

  const handleVoteComment = async (postId: string, commentId: string, delta: number) => {
    try {
      setIsLoading((prev) => ({ ...prev, [`comment_vote_${commentId}`]: true }));
      await axios.post(`${process.env.REACT_APP_BASE_URL}/comments/${commentId}/vote`, { delta });
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: updateCommentVote(prevComments[postId], commentId, delta),
      }));
    } catch (err) {
      handleNotification("Failed to update comment vote", "error");
      console.error(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, [`comment_vote_${commentId}`]: false }));
    }
  };

  const handleReplyComment = async (postId: string, parentCommentId: string, text: string) => {
    const newComment = {
      text,
      postId,
      parentCommentId,
      count: 0,
      userVote: 0,
    };
    try {
      // setIsLoading((prev) => ({ ...prev, [`comment_${postId}`]: true }));
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/comments/`, newComment);
      setComments({
        ...comments,
        [postId]: addReplyToComment(comments[postId], parentCommentId, res.data),
      });
      handleNotification("Reply added successfully");
    } catch (err) {
      handleNotification("Failed to reply comment", "error");
      console.error(err);
    } finally {
      // setIsLoading((prev) => ({ ...prev, [`comment_${postId}`]: false }));
    }
  };

  const handleEditComment = async (postId: string, commentId: string, newText: string) => {
    try {
      setIsLoading((prev) => ({ ...prev, [`comment_edit_${commentId}`]: true }));
      await axios.put(`${process.env.REACT_APP_BASE_URL}/comments/${postId}/${commentId}`, {
        text: newText,
      });
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: updateCommentText(prevComments[postId], commentId, newText),
      }));
      handleNotification("Comment updated successfully");
    } catch (err) {
      handleNotification("Failed to update comment", "error");
      console.error(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, [`comment_edit_${commentId}`]: false }));
    }
  };

  const handleDeleteComment = async (postId: string, commentId: string) => {
    try {
      setIsLoading((prev) => ({ ...prev, [`comment_delete_${commentId}`]: true }));
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/comments/${postId}/${commentId}`);
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: deleteComment(prevComments[postId], commentId),
      }));
      handleNotification("Comment deleted successfully");
    } catch (err) {
      handleNotification("Failed to delete comment", "error");
      console.error(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, [`comment_delete_${commentId}`]: false }));
    }
  };

  const handleLoadMore = (postId: string, parentCommentId: string) => {
    console.log(`Load more replies for post ${postId}, comment ${parentCommentId}`);
  };

  // Edit a post
  const handleEditPost = async (postId: string, newContent: string) => {
    try {
      setIsLoading((prev) => ({ ...prev, [`edit_${postId}`]: true }));
      await axios.put(`${process.env.REACT_APP_BASE_URL}/posts/${postId}`, { desc: newContent });
      setPosts((prevPosts) =>
        prevPosts.map((post) => (post._id === postId ? { ...post, desc: newContent } : post))
      );
      setEditingPost(null); // Exit edit mode
      handleNotification("Post updated successfully");
    } catch (err) {
      handleNotification("Failed to update post", "error");
      console.error(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, [`edit_${postId}`]: false }));
    }
  };

  // Delete a post
  const handleDeletePost = async (postId: string) => {
    try {
      setIsLoading((prev) => ({ ...prev, [`delete_${postId}`]: true }));
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/posts/${postId}`);
      setPosts((prevPosts) => prevPosts.filter((post) => post._id !== postId));
      handleNotification("Post deleted successfully");
    } catch (err) {
      handleNotification("Failed to delete post", "error");
      console.error(err);
    } finally {
      setIsLoading((prev) => ({ ...prev, [`delete_${postId}`]: false }));
    }
  };

  useEffect(() => {
    const getPosts = async () => {
      if (!artistId) return;

      try {
        setIsLoading((prev) => ({ ...prev, fetchPosts: true }));
        const res = await axios.get<PostType[]>(
          `${process.env.REACT_APP_BASE_URL}/posts/community/${artistId}`
        );
        setPosts(res.data);
        const newComments: Record<string, CommentType[]> = {};
        res.data.forEach((post) => {
          newComments[post._id] = post.comments || [];
        });
        setComments(newComments);
      } catch (err) {
        handleNotification("Failed to fetch posts", "error");
      } finally {
        setIsLoading((prev) => ({ ...prev, fetchPosts: false }));
      }
    };

    getPosts();
  }, [artistId]);

  const toggleCommentsVisibility = (postId: string) => {
    setVisibleComments((prev) => ({ ...prev, [postId]: !prev[postId] }));
  };

  const toggleCommentForm = (postId: string) => {
    setActiveCommentForm((prev) => (prev === postId ? null : postId));
    setVisibleComments((prev) => ({ ...prev, [postId]: true }));
  };

  const getCommentCount = (postId: string): number => {
    const postComments = comments[postId] || [];
    return postComments.reduce((total, comment) => total + 1 + (comment.replies?.length || 0), 0);
  };

  return (
    <Box sx={{ mx: { xs: 2, sm: 4, md: 8, lg: 12 }, my: { xs: 3, sm: 4, md: 6 } }}>
      <Button
        variant='contained'
        onClick={() => setIsPostBoxVisible(true)}
        sx={{
          mb: 2,
          backgroundColor: "#000",
          color: "#fff",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
          "&:hover": { backgroundColor: "#333" },
        }}
        disabled={isLoading.createPost}
      >
        New Post
      </Button>

      {isPostBoxVisible && (
        <PostCreationForm
          onCreatePost={handleCreatePost}
          onCancel={() => setIsPostBoxVisible(false)}
          onNotify={handleNotification}
          // isLoading={isLoading.createPost}
        />
      )}

      <Notification
        message={notificationMessage}
        show={showNotification}
        setShow={setShowNotification}
      />

      {posts.map((post) => (
        <Box key={post._id} sx={{ mb: 3 }}>
          <Post
            post={post}
            onVote={(delta) => handleVotePost(post._id, delta)}
            onAddComment={(text) => handleAddComment(post._id, text)}
            onEditPost={(newContent) => handleEditPost(post._id, newContent)}
            onDeletePost={() => handleDeletePost(post._id)}
            showCommentForm={activeCommentForm === post._id}
            onToggleCommentForm={() => toggleCommentForm(post._id)}
            isEditing={editingPost === post._id}
            toggleCommentsVisibility={toggleCommentsVisibility}
            visibleComments={visibleComments}
            getCommentCount={getCommentCount}
            // isLoading={isLoading[`vote_${post._id}`]}
          />

          <CommentsSection
            comments={comments[post._id] || []}
            onAddComment={(text) => handleAddComment(post._id, text)}
            onVoteComment={(commentId, delta) => handleVoteComment(post._id, commentId, delta)}
            onReplyComment={(parentCommentId, text) =>
              handleReplyComment(post._id, parentCommentId, text)
            }
            onLoadMore={(parentCommentId) => handleLoadMore(post._id, parentCommentId)}
            onEditComment={(commentId, newText) => handleEditComment(post._id, commentId, newText)}
            onDeleteComment={(commentId) => handleDeleteComment(post._id, commentId)}
            isVisible={visibleComments[post._id]}
            // isLoading={isLoading}
          />
        </Box>
      ))}
    </Box>
  );
};

// Helper functions for updating comments
const updateCommentVote = (
  comments: CommentType[],
  commentId: string,
  delta: number
): CommentType[] => {
  return comments.map((comment) => {
    if (comment._id === commentId) {
      return { ...comment, count: Math.max(0, comment.count + delta) };
    }
    if (comment.replies?.length > 0) {
      return { ...comment, replies: updateCommentVote(comment.replies, commentId, delta) };
    }
    return comment;
  });
};

const addReplyToComment = (
  comments: CommentType[],
  parentCommentId: string,
  newReply: CommentType
): CommentType[] => {
  return comments.map((comment) => {
    if (comment._id === parentCommentId) {
      return { ...comment, replies: [newReply, ...(comment.replies || [])] };
    }
    if (comment.replies?.length > 0) {
      return { ...comment, replies: addReplyToComment(comment.replies, parentCommentId, newReply) };
    }
    return comment;
  });
};

const updateCommentText = (
  comments: CommentType[],
  commentId: string,
  newText: string
): CommentType[] => {
  return comments.map((comment) => {
    if (comment._id === commentId) {
      return { ...comment, text: newText };
    }
    if (comment.replies?.length > 0) {
      return { ...comment, replies: updateCommentText(comment.replies, commentId, newText) };
    }
    return comment;
  });
};

const deleteComment = (comments: CommentType[], commentId: string): CommentType[] => {
  return comments.filter((comment) => {
    if (comment._id === commentId) {
      return false;
    }
    if (comment.replies?.length > 0) {
      comment.replies = deleteComment(comment.replies, commentId);
    }
    return true;
  });
};

export default PostsAndComments;
