import React, { useState, useEffect } from "react";
import { Stack, IconButton, Typography } from "@mui/material";
import { Downarrow, Uparrow } from "assets";
import Image from "components/Image";

interface VoteButtonsProps {
  initialCount: number;
  initialUserVote?: 1 | 0 | -1;
  onVote?: (delta: 1 | 0 | -1) => void;
}

const formatCount = (count: number): string => {
  if (count >= 1000) {
    return (count / 1000).toFixed(1) + "k";
  }
  return count.toString();
};

const VoteButtons: React.FC<VoteButtonsProps> = ({ initialCount, initialUserVote = 0, onVote }) => {
  const [count, setCount] = useState(initialCount);
  const [userVote, setUserVote] = useState<1 | 0 | -1>(initialUserVote);

  useEffect(() => {
    console.log("Current userVote:", userVote);
  }, [userVote]);

  const handleVote = (delta: 1 | 0) => {
    let newVote: 1 | 0 ;

    if (userVote === delta) {
      newVote = 0;
      setCount((prevCount) => prevCount - delta);
    } else {
      newVote = delta;
      setCount((prevCount) => prevCount - userVote + delta);
    }

    setUserVote(newVote);
    if (onVote) onVote(delta);
    console.log("Vote cast:", newVote);
  };

  const getIconFilter = (voteType: 1 | 0) => {
    if (voteType === 1 && userVote === 1) {
      return "invert(36%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(101%) contrast(107%)";
    }
    if (voteType === 0 && userVote === 0) {
      return "invert(40%) sepia(57%) saturate(2878%) hue-rotate(210deg) brightness(101%) contrast(107%)";
    }
    return "none";
  };

  return (
    <Stack direction='column' alignItems='center' spacing={1}>
      <IconButton
        onClick={() => handleVote(1)}
        size='small'
        sx={{
          padding: "4px",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "@media (max-width: 600px)": {
            padding: "2px",
          },
        }}
      >
        <Image
          src={Uparrow}
          alt='Upvote icon'
          width={16}
          height={16}
          sx={{
            filter: getIconFilter(1),
            "@media (max-width: 600px)": {
              width: 10,
              height: 8,
            },
          }}
        />
      </IconButton>
      <Typography variant='body2' fontWeight='light' color='#00000061' sx={{ fontSize: "12px", lineHeight: 0, my: "1px" }}>
        {/* {formatCount(count)} */}
      </Typography>
      <IconButton
        onClick={() => handleVote(0)}
        size='small'
        sx={{
          padding: "4px",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "@media (max-width: 600px)": {
            padding: "2px",
          },
        }}
      >
        <Image
          src={Downarrow}
          alt='Downvote icon'
          width={16}
          height={16}
          sx={{
            filter: getIconFilter(0),
            "@media (max-width: 600px)": {
              width: 10,
              height: 8,
            },
          }}
        />
      </IconButton>
    </Stack>
  );
};

export default VoteButtons;
