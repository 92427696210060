import { styled } from "@mui/material/styles";
import { Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { FansCard } from "components/cards";
import { UserProps } from "@types";

const ContentStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(5, 5),
  backgroundColor: "rgba(248, 248, 248, 1)",
  margin: "auto",
  overflow: "hidden",
}));

const FanCardWrapper = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

type UserListProps = {
  topFans: UserProps[]
}

export default function Fans({ topFans }: UserListProps) {
  return (
    <ContentStyle>
      <Typography
        variant='h3'
        sx={{
          color: "common.black",
          textTransform: "uppercase",
          fontWeight: "bold",
          // fontFamily: "Dela Gothic One, regular",
        }}
      >
        Top Fans of the Week
      </Typography>
      <Grid container spacing={3} sx={{ justifyContent: "left", mt: 5 }}>
        {topFans.slice(0, 10).map((card, index) => (
          <FanCardWrapper item key={index}>
            <Link to={`/fans/${card._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <FansCard
                index={index}
                avatarImg={card.avatarImg}
                username={card.username}
                points={card.points}
                _id={card._id}
              />
            </Link>
          </FanCardWrapper>
        ))}
      </Grid>
    </ContentStyle>
  );
}
