/* eslint-disable import/no-duplicates */
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, BoxProps, Typography } from "@mui/material";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          fontFamily: "'Raleway', sans-serif",
          fontSize: { xs: "16px", md: "26px" },          
          textTransform: "uppercase",
          color: "#000000",
          display: "inline-block",
        }}
      >
        SOUNDCTRL
      </Typography>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <RouterLink to="/" style={{ textDecoration: "none" }}>
      {logo}
    </RouterLink>
  );
}
