import React, { useRef, useState, useEffect } from "react";
import { Box, Stack, TextField, Link, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface TabBarProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
  showArtistinfoTab: boolean;
}

const TabBar: React.FC<TabBarProps> = ({ activeTab, onTabChange, showArtistinfoTab }) => {
  const tabs = [
    { label: "Community" },
    ...(showArtistinfoTab ? [{ label: "Artistinfo" }] : [])
  ];
  
  const [underlineStyle, setUnderlineStyle] = useState({ width: 0, left: 0 });
  const tabRefs = useRef<{ [key: string]: HTMLAnchorElement | null }>({});
  const stackRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const activeTabElement = tabRefs.current[activeTab];
    if (activeTabElement && stackRef.current) {
      const stackLeft = stackRef.current.getBoundingClientRect().left;
      const tabLeft = activeTabElement.getBoundingClientRect().left;
      const relativeLeft = tabLeft - stackLeft;
      
      setUnderlineStyle({
        width: activeTabElement.offsetWidth,
        left: relativeLeft
      });
    }
  }, [activeTab]);

  return (
    <Box sx={{ py: 1, mx: { xs: 2, md: 12 }, backgroundColor: "#f7f7f7", position: "relative" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack 
          ref={stackRef}
          direction="row" 
          spacing={{ xs: 2, sm: 5, md: 9 }} 
          sx={{ 
            position: "relative",
            pl: { xs: 3, sm: 4, md: 5 } // Added left padding here
          }}
        >
          {tabs.map((tab) => (
            <Link
              key={tab.label}
              ref={(el) => (tabRefs.current[tab.label] = el)}
              href="#"
              underline="none"
              onClick={() => onTabChange(tab.label)}
              sx={{
                color: activeTab === tab.label ? "#FD934C" : "rgba(0, 0, 0, 0.54)",
                fontSize: "16px",
                fontWeight: "500",
                position: "relative",
                paddingBottom: "4px",
                textDecoration: "none",
              }}
            >
              {tab.label}
            </Link>
          ))}
          <Box
            sx={{
              height: "3px",
              backgroundColor: "#FD934C",
              position: "absolute",
              bottom: "-4px",
              left: -16,
              transition: "all 0.3s ease",
              display: { xs: "block", md: "none" },
              width: `${underlineStyle.width}px`,
              transform: `translateX(${underlineStyle.left}px)`,
            }}
          />
        </Stack>

        <TextField
          placeholder="Search Topic"
          variant="outlined"
          size="small"
          sx={{
            width: { xs: "120px", sm: "140px", md: "160px", lg: "200px" },
            ml: "auto",
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#ffffff",
              padding: { xs: "2px 6px", sm: "2px 8px", md: "2px 10px", lg: "2px 11px" },
              borderRadius: 0,
              "& input": {
                padding: { xs: "8px", sm: "10px", md: "12px" },
                fontSize: { xs: "12px", sm: "13px", md: "14px", lg: "15px" },
                color: "rgba(0, 0, 0, 0.38)",
              },
              "& fieldset": {
                border: "none",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{
                    color: "#4F5660",
                    fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px" },
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    </Box>
  );
};

export default TabBar;