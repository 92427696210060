import { styled } from "@mui/material/styles";
import { Container, Divider, Stack, Typography, Link } from "@mui/material";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: "rgba(248, 248, 248, 1)",
  padding: theme.spacing(2, 6),
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <RootStyle>
      <Divider />
      <Container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          py: { xs: 2, md: 5 },
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 2, md: 6 }}
          sx={{ alignItems: "center" }}
        >
          <Typography
            component='p'
            variant='body2'
            sx={{
              fontSize: 13,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            ©{currentYear} SOUNDCTRL TECH, INC. All rights reserved.
          </Typography>
          <Stack direction='row' spacing={2} sx={{ alignItems: "center" }}>
            <Link href='/privacy' underline='none' sx={{ color: "black", fontSize: 13 }}>
              Privacy Policy
            </Link>
            <Typography variant='body2'>|</Typography>
            <Link href='/terms' underline='none' sx={{ color: "black", fontSize: 13 }}>
              Terms & Conditions
            </Link>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={3} sx={{ mt: { xs: 3, md: 0 } }}>
          <SocialLink
            href='https://www.instagram.com/soundctrl.xyz?igsh=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr'
            icon='ri:instagram-fill'
            label='Instagram'
          />
          <SocialLink href='https://x.com/soundctrlxyz' icon='logos:x' label='X' />
          <SocialLink
            href='https://m.youtube.com/@Soundctrlxyz'
            icon='mdi:youtube'
            label='YouTube'
          />
        </Stack>
      </Container>
    </RootStyle>
  );
}

function SocialLink({ href, icon, label }: { href: string; icon: string; label: string }) {
  return (
    <Link href={href} target='_blank' underline='none' sx={{ color: "black" }}>
      <Stack spacing={1} direction='row' alignItems='center'>
        <Icon icon={icon} width={24} height={24} />
        <Typography variant='subtitle2'>{label}</Typography>
      </Stack>
    </Link>
  );
}
