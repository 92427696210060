import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, List, ListItem, ListItemText, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { UserProps } from '@types';

interface AddModeratorModalProps {
  open: boolean;
  onClose: () => void;
  onAddModerator: (fan: string) => void;
  currentModerators: UserProps[];
  artistId?: string;
}

const AddModeratorModal: React.FC<AddModeratorModalProps> = ({ open, onClose, artistId, onAddModerator, currentModerators }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [fans, setFans] = useState<UserProps[] | []>([]);
  const [availableFans] = useState(['fan1', 'fan2', 'fan3', 'fan4', 'fan5']); // from backend API

  // const filteredFans = fans.filter(fan => 
  //   fan.toLowerCase().includes(searchTerm.toLowerCase()) && !currentModerators.includes(fan)
  // );

  console.log(currentModerators)
  useEffect(() => {
    const getFans = async() => {
      try{
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/users/fans/${artistId}`
        );
        setFans(res.data);
      }catch(err) {
        console.log(err)
      }
    };
    getFans();
  }, [artistId]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Add Moderator
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search fans..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          sx={{ mb: 2 }}
        />
        <List sx={{ maxHeight: 200, overflow: 'auto' }}>
          {fans.map((fan) => (
            <ListItem key={fan._id} disablePadding>
              <ListItemText primary={fan.username} />
              <Button onClick={() => onAddModerator(fan._id!)}>Add</Button>
            </ListItem>
          ))}
        </List>
        {fans.length === 0 && (
          <Typography variant="body2" sx={{ textAlign: 'center', mt: 2 }}>
            No available fans found
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default AddModeratorModal;