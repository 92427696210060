import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Stack,
  TextField,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import app from "../../../firebase";
import AttachmentMenu from "components/helpers/AttachmentMenu";
import MediaPreview from "components/helpers/MediaPreview";
import SendIcon from "@mui/icons-material/Send";

interface PostCreationFormProps {
  onCreatePost: (
    content: string,
    media: string | null,
    mediaType: "image" | "video" | "gif" | null
  ) => void;
  onCancel: () => void;
  onNotify: (message: string) => void;
}

const PostCreationForm: React.FC<PostCreationFormProps> = ({
  onCreatePost,
  onCancel,
  onNotify,
}) => {
  const [content, setContent] = useState("");
  const [media, setMedia] = useState<File | null>(null);
  const [mediaURL, setMediaURL] = useState<string | null>(null);
  const [mediaType, setMediaType] = useState<"image" | "video" | "gif" | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleSubmit = useCallback(() => {
    if (content.trim()) {
      onCreatePost(content, mediaURL, mediaType);

      const notificationMessage = `Post created${mediaType ? ` with ${mediaType}` : ""}`;
      onNotify(notificationMessage);

      setContent("");
      setMedia(null);
      setMediaType(null);
      setPreviewUrl(null);
      setMediaURL(null);
    }
  }, [content, mediaURL, mediaType, onCreatePost, onNotify]);

  const uploadFile = useCallback((file: File) => {
    const fileName = `media/${new Date().getTime()}_${file.name}`;
    const storage = getStorage(app);
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setLoading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.error("Error uploading file:", error);
        setLoading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setMediaURL(downloadURL);
          setLoading(false);
        });
      }
    );
  }, []);

  const handleMediaChange = useCallback((type: "image" | "video" | "gif") => {
    if (fileInputRef.current) {
      fileInputRef.current.accept =
        type === "image" ? "image/*" : type === "video" ? "video/*" : "image/gif";
      fileInputRef.current.click();
    }
    setAnchorEl(null);
  }, []);

  const handleFileInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const isGif = file.type === "image/gif";

      setMedia(file);
      setMediaType(isGif ? "gif" : file.type.startsWith("image/") ? "image" : "video");
      setPreviewUrl(URL.createObjectURL(file));
    }
  }, []);

  const handleRemoveMedia = useCallback(() => {
    setMedia(null);
    setMediaType(null);
    setPreviewUrl(null);
    setMediaURL(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }, []);

  const handleAttachmentClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  useEffect(() => {
    if (media) {
      uploadFile(media);
    }
  }, [media, uploadFile]);

  return (
    <Stack spacing={2}>
      <Box sx={{ position: "relative", width: "100%" }}>
        <TextField
          multiline
          rows={4}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          fullWidth
          placeholder='Write your post here...'
          variant='outlined'
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#222222",
              borderWidth: 1.2,
            },
            paddingBottom: "32px",
          }}
          InputProps={{
            style: { paddingBottom: "32px" },
          }}
        />
        <Box
          sx={{
            position: "absolute",
            bottom: "38px",
            right: "8px",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <input
            accept='image/*,video/*,.gif'
            style={{ display: "none" }}
            type='file'
            onChange={handleFileInputChange}
            ref={fileInputRef}
          />
          <IconButton
            sx={{
              color: "#FD934C",
              "&:hover": { backgroundColor: "#FFF3EB" },
              padding: "2px",
            }}
            onClick={handleAttachmentClick}
            aria-label='Attach file'
            size='small'
          >
            <AttachFileIcon />
          </IconButton>
          <AttachmentMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleMediaChange={handleMediaChange}
          />
          <IconButton
            sx={{
              color: "#222222",
              "&:hover": { backgroundColor: "transparent" },
              padding: "4px",
            }}
            onClick={onCancel}
            aria-label='Cancel post creation'
          >
            <CancelIcon />
          </IconButton>
          <IconButton
            sx={{
              color: "#000",
              "&:hover": { backgroundColor: "#333333" },
              padding: "4px",
            }}
            onClick={handleSubmit}
            disabled={!content.trim() || loading}
            aria-label='Post content'
          >
            {loading ? <CircularProgress size={20} color='inherit' /> : <SendIcon />}
          </IconButton>
        </Box>
      </Box>

      <MediaPreview
        media={media}
        mediaType={mediaType}
        previewUrl={previewUrl}
        handleRemoveMedia={handleRemoveMedia}
      />
    </Stack>
  );
};

export default PostCreationForm;
