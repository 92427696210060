import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { Typography, Paper, InputBase, Button, Grid } from "@mui/material";
import React, { useState } from "react";

const RootStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(10, 2),
  backgroundColor: "rgba(248, 248, 248, 1)",
  margin: "auto",
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(15, 10),
  },
}));

interface SearchFormProps {
  onSearch: (query: string) => void;
}

const SearchForm: React.FC<SearchFormProps> = ({ onSearch }) => {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  // const PRIMARY_DARKER = theme.palette.primary.darker;
  const [searchQuery, setSearchQuery] = useState("");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(searchQuery);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <RootStyle>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Typography
            variant="h4"
            sx={{
              color: "common.black",
              textTransform: "uppercase",
              fontWeight: "bold",
              // fontFamily: "Dela Gothic One, regular",
              textAlign: "center",
              [theme.breakpoints.up("sm")]: {
                fontSize: "2.5rem",
              },
              [theme.breakpoints.up("md")]: {
                fontSize: "3rem",
              },
            }}
          >
            Search for your favorite artist
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper
            elevation={0}
            component="form"
            onSubmit={handleSubmit}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "20px",
              [theme.breakpoints.up("sm")]: {
                p: "2px 6px",
              },
            }}
          >
            <Icon icon="bi:search" color="#000" width="20" height="20" />
            <InputBase
              placeholder="Search"
              inputProps={{ "aria-label": "Search" }}
              sx={{
                bgcolor: "common.white",
                padding: "6px 10px",
                flex: 1,
                [theme.breakpoints.up("sm")]: {
                  padding: "6px 15px",
                },
              }}
              value={searchQuery}
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                ml: 1,
                backgroundColor: PRIMARY_MAIN,
                "&:hover": {
                  backgroundColor: PRIMARY_DARK,
                },
              }}
            >
              Search
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </RootStyle>
  );
};

export default SearchForm;
