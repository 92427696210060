import React from 'react';
import { m } from 'framer-motion';
// @mui
import { styled } from "@mui/material/styles";
import { SxProps, Theme } from "@mui/material";
// assets
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ProgressBar from "./ProgressBar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }: { theme: Theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: "100%",
  height: "100%",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,
}));

const TypingText = styled("div")(({ theme }: { theme: Theme }) => ({
  fontSize: "1.5rem",
  color: theme.palette.text.primary,
  marginTop: theme.spacing(2),
  display: "inline-block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  borderRight: `0.15em solid ${theme.palette.text.primary}`,
  animation: "typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite"
}));

const MusicNoteAnimation = styled(m.div)(({ theme }: { theme: Theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "4rem",
  color: '#FD934C',
}));

// Adding keyframes for typing effect
const GlobalStyles = styled("style")`
  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }

  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: currentColor; }
  }
`;

// ----------------------------------------------------------------------

interface Props {
  isDashboard?: boolean;
  sx?: SxProps<Theme>;
}

const LoadingScreen: React.FC<Props> = ({ isDashboard, ...other }) => {
  return (
    <>
      <GlobalStyles />
      <ProgressBar />
      {!isDashboard && (
        <RootStyle {...other}>
          <MusicNoteAnimation
            initial={{ y: -20 }}
            animate={{ y: 20 }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "reverse",
            }}
          >
            <MusicNoteIcon fontSize="inherit" />
          </MusicNoteAnimation>
          <TypingText>Loading...</TypingText>
        </RootStyle>
      )}
    </>
  );
};

export default LoadingScreen;
