import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PeopleIcon from "@mui/icons-material/People";
import RuleIcon from "@mui/icons-material/Rule";
import InfoIcon from "@mui/icons-material/Info";
import Image from "components/Image";
import AddModeratorModal from "./components/AddModeratorModal";

import { TimeIcon, XIcon, IgIcon, SpotifyIcon } from "../../assets";
import Notification from "components/Notification";
import { SidebarProps, UserProps } from "@types";
import axios from "axios";

const Sidebar: React.FC<SidebarProps> = ({ artistName, memberCount, artistId, artistImage }) => {
  const [moderators, setModerators] = useState<UserProps[] | []>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [moderatorToDelete, setModeratorToDelete] = useState<string | null>(null);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAddModerator = async(newModerator: string) => {
    try{
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/moderator/add`,
        { userId: newModerator }
      );
      setModerators([...moderators, res.data]);
      setIsModalOpen(false);
      setNotificationMessage(`You have added @${res.data.username} as your mod.`);
      setShowNotification(true);
    }catch(err) {
      console.log(err);
    }
  };

  const handleDeleteClick = (moderator: string) => {
    setModeratorToDelete(moderator);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async() => {
    if (moderatorToDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/users/moderator/${moderatorToDelete}`)
        setModerators(moderators.filter((mod) => mod !== moderatorToDelete));
        setIsDeleteDialogOpen(false);
        // setNotificationMessage(`You have canceled @${moderatorToDelete} as your mod.`);
        setShowNotification(true);
        setModeratorToDelete(null);
      }catch(err) {
        console.log(err)
      }
    }
  };

  useEffect(() => {
    const getModerators = async() => {
      try{
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/users/moderator/${artistId}`
        );
        console.log(res.data)
        setModerators(res.data);
      }catch(err) {
        console.log(err)
      }
    }
    getModerators();
  }, [artistId]);

  const CommunityInfo = () => (
    <Box sx={{ backgroundColor: "#ffffff", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}>
      <Stack direction='row' alignItems='center' spacing={2} sx={{ p: 2 }}>
        <Box sx={{ 
          position: "relative", 
          display: "inline-block",
          width: 40,
          height: 40,
          padding: "3px"
        }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <Image
              src={artistImage}
              alt={artistName}
              fill
              style={{
                objectFit: "cover",
              }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: 12,
              height: 12,
              backgroundColor: "#43B581",
              borderRadius: "50%",
              border: "2px solid white",
              transform: "translate(25%, 25%)"
            }}
          />
        </Box>
        <Notification
          message={notificationMessage}
          show={showNotification}
          setShow={setShowNotification}
        />
        <Box>
          <Typography variant='h6'>{artistName}</Typography>
          <Typography variant='body2' color='textSecondary'>
            #verified
          </Typography>
        </Box>
      </Stack>
      {!isMobile && (
        <>
          <Typography sx={{ p: 2 }}>This is {artistName}'s official fan page.</Typography>
          <Typography variant='body2' color='textSecondary' sx={{ p: 2 }}>
            {memberCount.toLocaleString()} members
          </Typography>
          <Stack direction='row' spacing={2} sx={{ p: 2 }}>
            <IconButton size='small'>
              <Image src={TimeIcon} alt='Time Icon' width={24} height={24} />
            </IconButton>
            <IconButton size='small'>
              <Image src={XIcon} alt='X Icon' width={24} height={24} />
            </IconButton>
            <IconButton size='small'>
              <Image src={IgIcon} alt='Instagram Icon' width={24} height={24} />
            </IconButton>
            <IconButton size='small'>
              <Image src={SpotifyIcon} alt='Spotify Icon' width={24} height={24} />
            </IconButton>
          </Stack>
        </>
      )}
    </Box>
  );

  const ModeratorsList = () => (
    <Box>
      {moderators.map((moderator, index) => (
        <Box
          key={moderator._id}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 16px",
            borderBottom: index < moderators.length - 1 ? "1px solid #f0f0f0" : "none",
          }}
        >
          <Typography variant='body2'>
            {moderator.username}{" "}
            <Box
              component='span'
              sx={{
                backgroundColor: "#FD934C",
                color: "white",
                px: 1,
                borderRadius: "4px",
                ml: 1,
                fontSize: "0.75rem",
              }}
            >
              MOD
            </Box>
          </Typography>
          <Box>
            <IconButton size='small'>
              <HomeIcon sx={{ width: 14, height: 14 }} />
            </IconButton>
            <IconButton size='small' onClick={() => handleDeleteClick(moderator._id!)}>
              <DeleteIcon sx={{ width: 14, height: 14, color: "red" }} />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Box>
  );

  const RulesList = () => (
    <Typography component='ol' sx={{ listStyle: "decimal", pl: 2 }}>
      <li>
        <Typography sx={{ mb: 3 }}>
          <strong>Submissions must be verifiable.</strong> Please link directly to a reliable source
          that supports every claim in your post title.{" "}
          <strong>Images alone do not count as valid references.</strong> Videos are fine as long as
          they come from reputable sources (e.g. BBC, Discovery, etc).
        </Typography>
      </li>
      <li>
        <Typography sx={{ mb: 3 }}>
          <strong>No personal opinions, anecdotes, or subjective statements</strong> (e.g. "TIL xyz
          is a great movie").
        </Typography>
      </li>
      <li>
        <Typography sx={{ mb: 3 }}>
          <strong>No recent sources.</strong> Any sources (blog, article, press release, video,
          etc.) more recent than two months are not allowed.
        </Typography>
      </li>
    </Typography>
  );

  return (
    <Box sx={{ my: 6, px: { xs: 2, md: 12 } }}>
      {isMobile ? (
        <>
          <CommunityInfo />
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <InfoIcon />
                <Typography>Community Info</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>This is {artistName}'s official fan page.</Typography>
              <Typography variant='body2' color='textSecondary'>
                {memberCount.toLocaleString()} members
              </Typography>
              <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
                <IconButton size='small'>
                  <Image src={TimeIcon} alt='Time Icon' width={24} height={24} />
                </IconButton>
                <IconButton size='small'>
                  <Image src={XIcon} alt='X Icon' width={24} height={24} />
                </IconButton>
                <IconButton size='small'>
                  <Image src={IgIcon} alt='Instagram Icon' width={24} height={24} />
                </IconButton>
                <IconButton size='small'>
                  <Image src={SpotifyIcon} alt='Spotify Icon' width={24} height={24} />
                </IconButton>
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <PeopleIcon />
                <Typography>Moderators</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <ModeratorsList />
              <Button startIcon={<AddIcon />} onClick={() => setIsModalOpen(true)} sx={{ mt: 2 }}>
                Add Moderator
              </Button>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <RuleIcon />
                <Typography>Rules</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <RulesList />
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <>
          <CommunityInfo />
          <Box>
            <Button
              variant='outlined'
              sx={{
                borderColor: "#FD934C",
                color: "#FD934C",
                borderRadius: "50px",
                px: 10,
                py: 2.2,
                marginTop: 4,
                "&:hover": {
                  backgroundColor: "#FFF5ED",
                  borderColor: "#FD934C",
                },
              }}
            >
              CREATE POST
            </Button>
          </Box>
          <Box sx={{ mt: 6, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}>
            <Box
              sx={{
                backgroundColor: "#222222",
                padding: "16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant='subtitle1' color='white'>
                MODERATORS
              </Typography>
              <IconButton onClick={() => setIsModalOpen(true)} size='small' sx={{ color: "white" }}>
                <AddIcon />
              </IconButton>
            </Box>
            <Box sx={{ backgroundColor: "#fff" }}>
              <ModeratorsList />
            </Box>
          </Box>
          <Box sx={{ mt: 6, boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)" }}>
            <Box sx={{ backgroundColor: "#222222", padding: "16px" }}>
              <Typography variant='subtitle1' color='white'>
                RULES
              </Typography>
            </Box>
            <Box sx={{ padding: "16px", backgroundColor: "#ffffff" }}>
              <RulesList />
            </Box>
          </Box>
        </>
      )}

      <AddModeratorModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddModerator={handleAddModerator}
        currentModerators={moderators}
        artistId={artistId}
      />

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to remove this mod? @{moderatorToDelete}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color='error'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Sidebar;
