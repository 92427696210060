import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WaitlistProps } from "@types";

const initialState = {
    waitlists: [] as WaitlistProps[],
    isLoading: false,
    error: false,
}

const waitlistSlice = createSlice({
    name: "waitlist",
    initialState,
    reducers: {
        getWaitlistStart: (state) => {
            state.isLoading = true;
            state.error = false;
        },
        getWaitlistSuccess: (state, action: PayloadAction<WaitlistProps[]>) => {
            state.waitlists = action.payload;
            state.isLoading = false;
        },
        getWaitlistFailure: (state) => {
            state.error = true;
            state.isLoading = false;
        },
        updateWaitlistStart: (state) => {
            state.isLoading = true;
            state.error = false;
        },
        updateWaitlistSuccess: (state, action: PayloadAction<WaitlistProps>) => {
            state.waitlists[
                state.waitlists.findIndex((item) => item._id === action.payload._id)
            ] = action.payload;
            state.isLoading = false;
        },
        updateWaitlistFailure: (state) => {
            state.error = true;
            state.isLoading = false;
        },
    }
})

export const {
    getWaitlistFailure,
    getWaitlistStart,
    getWaitlistSuccess,
    updateWaitlistStart,
    updateWaitlistFailure,
    updateWaitlistSuccess,
} = waitlistSlice.actions;

export default waitlistSlice.reducer;