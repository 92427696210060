import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./redux-slices/UserSlice";
import bookingReducer from "./redux-slices/BookingSlice";
import waitlistReducer from "./redux-slices/WaitlistSlice";
import fansReducer from "./redux-slices/FansSlice"
import artistReducer from "./redux-slices/ArtistSlice"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({ 
  user: userReducer, 
  fans: fansReducer,
  artist: artistReducer,
  booking: bookingReducer,
  waitlist: waitlistReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    devTools: false
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
