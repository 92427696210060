import React from "react";
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import ThemeLocalization from "./components/ThemeLocalization";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import usePageTracking from "./hooks/usePageTracking";


if (process.env.NODE_ENV === "production") disableReactDevTools();

const App: React.FC = () => {
  usePageTracking(); 

  return (
    <ThemeProvider>
      <ThemeLocalization>
        <NotistackProvider>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <ScrollToTop />
              <Router />
          </MotionLazyContainer>
        </NotistackProvider>
      </ThemeLocalization>
    </ThemeProvider>
  );
};

export default App;
