import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import VideocamIcon from '@mui/icons-material/Videocam';
import GifIcon from '@mui/icons-material/Gif';

type AttachmentMenuProps = {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  handleMediaChange: (mediaType: 'image' | 'video' | 'gif') => void;
};

const AttachmentMenu: React.FC<AttachmentMenuProps> = ({ anchorEl, handleClose, handleMediaChange }) => (
  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
    {['image', 'video', 'gif'].map((type) => (
      <MenuItem key={type} onClick={() => handleMediaChange(type as 'image' | 'video' | 'gif')}>
        {type === 'image' ? (
          <AddPhotoAlternateIcon />
        ) : type === 'video' ? (
          <VideocamIcon />
        ) : (
          <GifIcon />
        )}
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </MenuItem>
    ))}
  </Menu>
);

export default AttachmentMenu;
