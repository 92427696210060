import { Box, Button, Stack, Typography } from "@mui/material";
import { ArtistProps } from "@types";
import Avatar from "components/Avatar";

export default function FandomCard(artist: ArtistProps) {
  return (
    <Box
      sx={{
        borderRadius: 2,
        bgcolor: "common.black",
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack
        spacing={1}
        sx={{
          paddingInline: { xs: 1.5, sm: 2 },
          paddingBlock: { xs: 1.5, sm: 2 },
          flexGrow: 1,
        }}
      >
        <Typography
          variant='subtitle1'
          sx={{ color: "common.white", fontSize: { xs: "0.75rem", sm: "0.875rem" } }}
        >
          12/MONTHS
        </Typography>
        <Typography
          variant='caption'
          sx={{
            color: "common.white",
            textTransform: "uppercase",
            fontSize: { xs: "0.625rem", sm: "0.75rem" },
          }}
        >
          fan TIME
        </Typography>
      </Stack>
      <Stack
        direction='row'
        sx={{
          bgcolor: "common.white",
          padding: { xs: 0.5, sm: .5 },
          borderRadius: { xs: '0 0 8px 8px', sm: 1 },
        }}
        justifyContent='space-between'
        alignItems="center"
      >
        <Stack direction='row' spacing={1} alignItems="center">
          <Avatar src={artist.avatarImg} alt='user avatar' sx={{ width: { xs: 24, sm: 32 }, height: { xs: 24, sm: 32 } }} />
          <Box>
            <Typography sx={{ fontSize: { xs: "0.625rem", sm: "0.75rem" }, fontWeight: 'bold' }}>
              {artist.username}
            </Typography>
            <Typography variant='caption' sx={{ fontSize: { xs: "0.5rem", sm: "0.625rem" } }}>
              @{artist.twitter}
            </Typography>
          </Box>
        </Stack>
        <Button
          variant='contained'
          size='small'
          sx={{
            bgcolor: "common.black",
            color: "common.white",
            textTransform: "uppercase",
            borderRadius: 1,
            boxShadow: "none",
            fontSize: { xs: "0.5rem", sm: "0.625rem" },
            padding: { xs: "2px 4px", sm: "4px 8px" },
            minWidth: 'auto',
          }}
        >
          Subscribe
        </Button>
      </Stack>
    </Box>
  );
}