import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/JWTContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <AuthProvider>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={clientId}>
        <Provider store={store}>
          <PersistGate loading='null' persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </AuthProvider>
);
