import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { ArtistProps } from "@types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  artists: [] as ArtistProps[],
  isFetching: false,
  error: false,
};

const artistSlice = createSlice({
  name: "artist",
  initialState,
  reducers: {
    setArtists: (state, action: PayloadAction<ArtistProps[]>) => {
      state.artists = action.payload;
    },
  },
});

export const { setArtists } = artistSlice.actions;
export default artistSlice.reducer;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
