import { Grid, Typography, Link } from "@mui/material";
import { ArtistCommunityCard } from "components/cards";
import { styled } from "@mui/material/styles";
import { ArtistProps } from "@types";
import { useLocation } from 'react-router-dom';

const ContentStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2, 2),
}));

type TrendingArtistsProps = {
  trending: ArtistProps[];
  isExplore?: boolean;  
};

export default function TrendingArtists({ trending, isExplore = false }: TrendingArtistsProps) {
  const displayCount = isExplore ? 24 : 6;
  // console.log('Display Count:', displayCount); 
  // console.log('Trending length:', trending.length); 
  // console.log('isExplore:', isExplore);

  return (
    <ContentStyle>
      <Typography
        variant="h3"
        sx={{
          color: "common.black",
          textTransform: "uppercase",
          fontWeight: "bold",
          textAlign: "center"
        }}
      >
        Trending Communities
      </Typography>
      <Grid 
        container 
        sx={{ 
          my: 5, 
          width: "100%",
        }}
      >
        {trending.slice(0, displayCount).map((artist) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={isExplore ? 4 : 6} 
            lg={isExplore ? 3 : 4} 
            key={artist._id}
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Link 
              href={`/book/${artist.username}`}
              sx={{
                textDecoration: 'none',
                width: '100%',
                maxWidth: { xs: '100%', sm: '300px' }
              }}
            >
              <ArtistCommunityCard
                _id={artist._id}
                avatarImg={artist.avatarImg}
                username={artist.username}
                name={artist.name}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </ContentStyle>
  );
}