import React from "react";
import { Box, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

interface MediaPreviewProps {
  media: File | null;
  mediaType: "gif" | "image" | "video" | null;
  previewUrl: string | null;
  handleRemoveMedia: () => void;
}

const MediaPreview: React.FC<MediaPreviewProps> = ({
  mediaType,
  previewUrl,
  handleRemoveMedia,
}) => {
  // Add the guard clause here to return null if no mediaType or previewUrl is provided
  if (!mediaType || !previewUrl) return null;

  return (
    <Box position="relative" width="fit-content">
      {mediaType === "gif" ? (
        <img src={previewUrl} alt="GIF Preview" style={{ maxWidth: "100%", maxHeight: "200px" }} />
      ) : mediaType === "image" ? (
        <img src={previewUrl} alt="imgreview" style={{ maxWidth: "100%", maxHeight: "200px" }} />
      ) : (
        <video src={previewUrl} controls style={{ maxWidth: "100%", maxHeight: "200px" }} />
      )}

      <IconButton
        size="small"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          background: "white",
        }}
        onClick={handleRemoveMedia}
        aria-label="Remove media"
      >
        <CancelIcon />
      </IconButton>
    </Box>
  );
};

export default MediaPreview;
