import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, Button, Skeleton } from "@mui/material";
import { ArtistProps } from "@types";
import { Link } from "react-router-dom";
import Avatar from "components/Avatar";
import Image from "components/Image";
import { primaryButtonStyles } from "utils/cssStyles";

const subscribeButtonStyle = {
  width: "auto",
  height: "45px",
  padding: "10px 20px",
  borderRadius: "20px",
};

const ArtistCard = ({ bannerImg, avatarImg, username, _id, fans, name }: ArtistProps) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      // Mock loading complete after 2 seconds
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Box sx={{ bgcolor: "rgba(237, 237, 237, 1)", cursor: "pointer", borderRadius: "20px" }}>
      <Stack direction='row'>
        {loading ? (
          <Skeleton variant="rectangular" width="241px" height="195px" sx={{ borderRadius: "20px" }} />
        ) : (
          <Image
            src={bannerImg}
            alt='artist'
            sx={{ width: "241px", height: "195px", borderRadius: "20px", opacity: "1" }}
          />
        )}

        <Stack sx={{ paddingTop: 8, paddingLeft: 2, paddingRight: 2, alignItems: "center" }}>
          <Box sx={{ textAlign: "center" }}>
            {loading ? (
              <Skeleton variant="text" width="80%" />
            ) : (
              <Typography variant='h4' sx={{ color: "common.black", textTransform: "uppercase" }}>
                {fans}
              </Typography>
            )}
            <Typography
              variant='subtitle2'
              sx={{ color: "common.black", textTransform: "uppercase", whiteSpace: "nowrap" }}
            >
              {loading ? <Skeleton variant="text" width="60%" /> : "fan base"}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Box
        sx={{
          padding: 1,
          bgcolor: "common.white",
          borderRadius: 1.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction='row' spacing={1}>
          {loading ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Avatar src={avatarImg} alt='artist avatar' />
          )}
          <Box>
            {loading ? (
              <>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="60%" />
              </>
            ) : (
              <>
                <Typography variant='subtitle1' sx={{ color: "common.black" }}>
                  {name}
                </Typography>
                <Typography variant='subtitle2' sx={{ color: "grey.800" }}>
                  @{username}
                </Typography>
              </>
            )}
          </Box>
        </Stack>
        <Link to={`/book/${username}`} state={{ id: _id }}>
          <Button variant='contained' sx={{ ...primaryButtonStyles, ...subscribeButtonStyle }}>
            {loading ? <Skeleton variant="text" width="100%" height="20px" /> : "Join Fandom"}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default ArtistCard;
